import {
    gsap,
    ScrollTrigger,
    ScrollToPlugin,
    CSSRulePlugin,
    CSSPlugin
} from "gsap/all";

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin, CSSRulePlugin, CSSPlugin);

export async function handleActiveLink() {
    let links = gsap.utils.toArray(".navbar-content-list .navbar-content-list-link");
    links.forEach(a => {
        gsap.to(`${a.getAttribute('class')}`, {
            delay: 0,
            duration: 0,
            ease: "power4.out",
            scrollTrigger: {
                trigger: document.getElementById(a.getAttribute('data-section')),
                start: "top center",
                end: 'top 0%',
                onToggle: self => self.isActive && setActive(a),
            },
        });
    });

    function setActive(link) {
        links.forEach(el => el.classList.remove("active"));
        link.classList.add("active");
    }
}