<script setup>
import {onMounted, ref} from "vue";
import {useI18n} from "vue-i18n";
import {useRoute, useRouter} from "vue-router";

const {t} = useI18n(); //
const router = useRouter();
const route = useRoute();
const fixedTop = ref(false);
const menuIsOpen = ref(false);
const scrollFunction = () => {
  const mainNavbarSub = document.getElementById("navbar");
  if (mainNavbarSub) {
    if (
        document.body.scrollTop > 500 ||
        document.documentElement.scrollTop > 500
    ) {
      fixedTop.value = true;
    } else {
      fixedTop.value = false;
    }
  }
};
const socialLinks = [
  // {href: '#', label: 'youtube', component: 'youtube-icon'},
  // {href: '#', label: 'snap', component: 'snap-icon'},
  {href: 'https://www.tiktok.com/@Rabbe3.Sa', label: 'tiktok', component: 'tiktok-icon'},
  // {href: '#', label: 'telegram', component: 'telegram-icon'},
  // {href: '#', label: 'linkedin', component: 'linkedin-icon'},
  // {href: '#', label: 'whatsapp', component: 'whatsapp-icon'},
  {href: 'https://www.instagram.com/Rabbe3.Sa/', label: 'instagram', component: 'instagram-icon'},
  {href: 'https://x.com/Rabbe3_SA', label: 'twitter', component: 'twitter-icon'},
  // {href: '#', label: 'facebook', component: 'facebook-icon'},
];
const changeLanguage = (code) => {
  menuIsOpen.value = false;
  router
      .push({
        name: router.currentRoute.name,
      })
      .then(() => {
        window.location.reload();
      });
};

onMounted(() => {
  scrollFunction();
  window.addEventListener("scroll", scrollFunction);
});
</script>
<template>
  <div class="max-width">

    <!-- <div id="not-fill-navbar"></div> -->
    <div
        id="navbar"

        :class="[
      fixedTop ? 'navbar fixed-top' : 'navbar',
      menuIsOpen ? 'menu-is-open' : '',
    ]"
    >
      <div class="navbar-sub">
        <router-link
            :to="{ name: 'home', hash: '#header', }"
            class="brand"
        >
          <app-logo
          />
        </router-link>
        <button class="menu" @click="menuIsOpen = !menuIsOpen">
          <menu-2-rec-icon/>
        </button>
        <div
            class="navbar-content-back"
            @click="menuIsOpen = !menuIsOpen"
            :class="menuIsOpen ? 'show' : ''"
        ></div>
        <div class="navbar-content" :class="menuIsOpen ? 'open' : ''">
          <div class="menu-header">
            <router-link
                :to="{
              name: 'home',
              hash: '#header',

            }"
                class="close-brand "
            >
              <app-logo
              />
            </router-link>
            <button class="close-menu" @click="menuIsOpen = !menuIsOpen">
              <cross-rec-icon/>
            </button>
          </div>

          <div class="navbar-content-list">
            <div class="spacer"></div>
            <router-link
                data-section="header"
                :to="{
              name: 'home',
              hash: '#header',

            }"
                class="navbar-content-list-link active"
                @click="menuIsOpen = false"
            >
              {{ $t("navbar.Home") }}
            </router-link>
            <router-link
                data-section="about"
                :to="{
              name: 'home',
              hash: '#about',
             
            }"
                class="navbar-content-list-link"
                @click="menuIsOpen = false"
            >
              {{ $t("navbar.About the app") }}
            </router-link>
            <router-link
                data-section="create-game"
                :to="{
              name: 'home',
              hash: '#create-game',

            }"
                class="navbar-content-list-link"
                @click="menuIsOpen = false"
            >
              {{ $t("navbar.features") }}
            </router-link>

            <router-link
                :to="{ name: 'home', hash: '#header', }"
                class="brand-middle"
            >
              <app-logo
              />
            </router-link>

            <router-link
                data-section="testimonials"
                :to="{
              name: 'home',
              hash: '#testimonials',

            }"
                class="navbar-content-list-link"
                @click="menuIsOpen = false"
            >
              {{ $t("navbar.about us") }}
            </router-link>
            <router-link
                data-section="FAQ"
                :to="{
              name: 'home',
              hash: '#FAQ',

            }"
                class="navbar-content-list-link"
                @click="menuIsOpen = false"
            >
              {{ $t("navbar.FAQ") }}
            </router-link>
            <router-link
                data-section="download"
                :to="{
              name: 'home',
              hash: '#download',

            }"
                class="navbar-content-list-link"
                @click="menuIsOpen = false"
            >
              {{ $t("navbar.download app") }}
            </router-link>
            <router-link
                :to="{
             name: 'privacy-policy'

            }"
                class="navbar-content-list-link show-md"
                @click="menuIsOpen = false"
            >
              {{ $t("navbar.privacy policy") }}
            </router-link>
            <router-link
                :to="{
                name: 'terms',
              }"
                class="navbar-content-list-link show-md"
                @click="menuIsOpen = false"
            >
              {{ $t("navbar.terms and conditions") }}
            </router-link>

            <div class="spacer"></div>
          </div>

          <div class="navbar-med-divider"></div>
          <div class="nav-social-list">
            <a
                v-for="link in socialLinks"
                :key="link.label"
                :href="link.href"
                class="nav-social-list-link"
                target="_blank"
                rel="noopener"
            >
              <label class="icon-label">
                <component :is="link.component"/>
              </label>
            </a>


          </div>
        </div>
      </div>
    </div>
  </div>
</template>
