import appLogoIcon from "./app-logo.svg";
import appLogo2Icon from "./app-logo-2.svg";
import appLogo3Icon from "./app-logo-3.svg";
import rabbe3Shape1 from "./rabbe3-shape-1.svg";
import rabbe3Shape2 from "./rabbe3-shape-2.svg";
import downloadAppIcon from "./downloadApp.svg";
import trustHeartIcon from "./trustHeart.svg";
import CompletedOrdersIcon from "./CompletedOrders.svg";
import NumberOfProductsFoundIcon from "./NumberOfProductsFound.svg";
import RestaurantsFoundIcon from "./RestaurantsFound.svg";
import usersImagesIcon from "./usersImages.svg";
import starsIcon from "./stars.svg";
import starIcon from "./star.svg";
import appleIcon from "./apple.svg";
import googlePlayIcon from "./google-play.svg";
import minusIcon from "./minus.svg";
import plusIcon from "./plus.svg";
import menu2RecIcon from "./menu-2-rec.svg";
import crossRecIcon from "./cross-rec.svg";
import facebookIcon from "./facebook.svg";
import twitterIcon from "./twitter.svg";
import instagramIcon from "./instagram.svg";
import whatsappIcon from "./whatsapp.svg";
import googleStoreIcon from "./google-store.svg";
import appleStoreIcon from "./apple-store.svg";
import emailIcon from "./email.svg";
import telegramIcon from "./telegram.svg";
import snapIcon from "./snap.svg";
import youtubeIcon from "./youtube.svg";
import tiktokIcon from "./tiktok.svg";
import linkedinIcon from "./linkedin.svg";
import rabbe3ShapeVertical from './rabbe3-shape-vertical.svg'
import arrowIcon from './arrow.svg'
import email2Icon from './email2.svg'
import personIcon from './person.svg'
import uploadIcon from './upload.svg'
import descIcon from './desc.svg'
import docIcon from './doc.svg'
import closeIcon from './close.svg'

// .......................................................


const svgvendors = (app) => {
    return app
        .component("close-icon", closeIcon)
        .component("doc-icon", docIcon)
        .component("desc-icon", descIcon)
        .component("upload-icon", uploadIcon)
        .component("person-icon", personIcon)
        .component("email2-icon", email2Icon)
        .component("app-logo", appLogoIcon)
        .component("app-logo-2", appLogo2Icon)
        .component("app-logo-3", appLogo3Icon)
        .component("rabbe3-shape-1", rabbe3Shape1)
        .component("rabbe3-shape-2", rabbe3Shape2)
        .component("rabbe3-shape-vertical", rabbe3ShapeVertical)
        .component("google-store-icon", googleStoreIcon)
        .component("apple-store-icon", appleStoreIcon)
        .component("email-icon", emailIcon)
        .component("telegram-icon", telegramIcon)
        .component("snap-icon", snapIcon)
        .component("youtube-icon", youtubeIcon)
        .component("tiktok-icon", tiktokIcon)
        .component("linkedin-icon", linkedinIcon)
        .component("download-app-icon", downloadAppIcon)
        .component("trust-heart-icon", trustHeartIcon)
        .component("completed-orders-icon", CompletedOrdersIcon)
        .component("number-of-products-found-icon", NumberOfProductsFoundIcon)
        .component("restaurants-found-icon", RestaurantsFoundIcon)
        .component("users-images-icon", usersImagesIcon)
        .component("stars-icon", starsIcon)
        .component("star-icon", starIcon)
        .component("apple-icon", appleIcon)
        .component("google-play-icon", googlePlayIcon)
        .component("minus-icon", minusIcon)
        .component("plus-icon", plusIcon)
        .component("menu-2-rec-icon", menu2RecIcon)
        .component("cross-rec-icon", crossRecIcon)
        .component("facebook-icon", facebookIcon)
        .component("twitter-icon", twitterIcon)
        .component("instagram-icon", instagramIcon)
        .component("whatsapp-icon", whatsappIcon)
        .component("arrow-icon", arrowIcon)

        ;
};
export default svgvendors;