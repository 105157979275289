<script setup>

const getYear = () => {
  return new Date().getFullYear();
};
const socialLinks = [
  // {href: '#', label: 'youtube', component: 'youtube-icon'},
  // {href: '#', label: 'snap', component: 'snap-icon'},
  {href: 'https://www.tiktok.com/@Rabbe3.Sa', label: 'tiktok', component: 'tiktok-icon'},
  // {href: '#', label: 'telegram', component: 'telegram-icon'},
  // {href: '#', label: 'linkedin', component: 'linkedin-icon'},
  // {href: '#', label: 'whatsapp', component: 'whatsapp-icon'},
  {href: 'https://www.instagram.com/Rabbe3.Sa/', label: 'instagram', component: 'instagram-icon'},
  {href: 'https://x.com/Rabbe3_SA', label: 'twitter', component: 'twitter-icon'},
  // {href: '#', label: 'facebook', component: 'facebook-icon'},
];
</script>
<template>
  <footer class=" max-width">
    <div class="footer-content">
      <div class="footer-content-grid">
        <div>
          <app-logo-3 class="footer-logo"/>
          <p
              class="footer-description text-14"
              v-html="
              $t(
                'common.about_rabbe_aden'
              )
            "
          ></p>
        </div>
        <div class="footer-list">
          <router-link
              :to="{
                name: 'home',
                hash: '#header',
              }"
              :data-text="$t('navbar.Home')"
              class="footer-list-link rabbe3-text-hover"
          >
            {{ $t("navbar.Home") }}
          </router-link>
          <router-link
              :to="{
                name: 'home',
                hash: '#about',
              }"
              :data-text="$t('navbar.About the app')"
              class="footer-list-link rabbe3-text-hover"
          >
            {{ $t("navbar.About the app") }}
          </router-link>
          <router-link
              :to="{
                name: 'home',
                hash: '#create-game',
              }"
              :data-text="$t('navbar.features')"
              class="footer-list-link  rabbe3-text-hover"
          >
            {{ $t("navbar.features") }}
          </router-link>
          <router-link
              :to="{
                name: 'home',
                hash: '#testimonials',
              }"
              :data-text="$t('navbar.about us')"
              class="footer-list-link  rabbe3-text-hover"
          >
            {{ $t("navbar.about us") }}
          </router-link>
          <router-link
              :to="{
                name: 'home',
                hash: '#FAQ',
              }"
              :data-text="$t('navbar.FAQ')"
              class="footer-list-link rabbe3-text-hover"
          >
            {{ $t("navbar.FAQ") }}
          </router-link>

        </div>
        <div class="footer-contact-list">
          <div>
            <div class="footer-contact-list-text">
              راسلنا وتساب
            </div>

            <a
                href="tel:+966506819535"
                class="footer-contact-list-link "
            >
              <label class="icon-label">
                <whatsapp-icon/>
              </label>
              <span class=" rabbe3-text-hover"
                    data-text="+966 506819535"
                    dir="ltr">+966 506819535</span>
            </a>
          </div>
          <div>
            <div class="footer-contact-list-text">
              راسلنا ايميل
            </div>

            <a
                href="mailto:info@rabbe3.com"
                class="footer-contact-list-link"
            >
              <label class="icon-label">
                <email-icon/>
              </label>
              <span class=" rabbe3-text-hover"
                    data-text="info@rabbe3.com">
           info@rabbe3.com
            </span>
            </a>
          </div>
        </div>
        <div class=" footer-content-grid-download-app">
          <h2 class="footer-content-grid-download-app-title">
            {{ $t("common.Get the app") }}
          </h2>
          <a
              href="#"
              class="download-btn"
          >
            <div class="download-btn-text">

              <div class="download-btn-text__title">Available on the</div>
              <div class="download-btn-text__subtitle">App Store</div>
            </div>
            <apple-store-icon/>
          </a>
          <a
              href="#"
              class="download-btn"
          >
            <div class="download-btn-text">

              <div class="download-btn-text__title">GET IT ON</div>
              <div class="download-btn-text__subtitle">Google Play</div>
            </div>
            <google-store-icon/>
          </a>
        </div>
      </div>
      <label class="footer-hr"/>
      <div class="footer-content-flex">
        <div class="footer-left text-16">
          مؤسسة إرث الحلول المبتكرة. جميع الحقوق محفوظة. 2024 ©
        </div>
        <div class="footer-social-list">
          <a
              v-for="link in socialLinks"
              :key="link.label"
              :href="link.href"
              class="footer-social-list-link"
              target="_blank"
              rel="noopener"
          >
            <label class="icon-label">
              <component :is="link.component"/>
            </label>
          </a>
        </div>
        <h2 class="footer-rights">
          <router-link
              :to="{
                name: 'privacy-policy'
              }"
              class="footer-rights-link text-16 rabbe3-text-hover"
              :data-text="$t('navbar.privacy policy')"
          >
            {{ $t("navbar.privacy policy") }}
          </router-link>
          <router-link
              :to="{
                name: 'terms',
              }"
              class="footer-rights-link text-16 rabbe3-text-hover"
              :data-text="$t('navbar.terms and conditions')"
          >
            {{ $t("navbar.terms and conditions") }}
          </router-link>
        </h2>
      </div>
    </div>
  </footer>
</template>