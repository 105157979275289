import {
    h,
    resolveComponent
} from "vue";
import main from "./middleware/middleware";

export default [
    {
        path: '',
        name: 'home',
        component: () => import("../views/Home.vue"),
        meta: {
            middleware: [main],
        },
    },
    {
        path: 'privacy-policy',
        name: 'privacy-policy',
        component: () => import("../views/PrivacyPolicy.vue"),
        meta: {
            middleware: [main],
        },
    },
    {
        path: 'terms',
        name: 'terms',
        component: () => import("../views/Terms.vue"),
        meta: {
            middleware: [main],
        },
    },
];