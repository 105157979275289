import {
  createRouter,
  createWebHistory
} from 'vue-router'
import i18n from "../libs/i18n";
import pages from "./pages";
import main from "./middleware/middleware";
const router = createRouter({
  history: createWebHistory(),
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        top: 100,
        behavior: "smooth",
      };
    }

    return {
      top: 0
    };
  },
  routes: [
    //   {
    //   path: "/",
    //   redirect: `/${i18n.global.locale.value}/`,
    // },
    {
      path: "/",
      children: [
        ...pages,
        {
          path: ":pathMatch(.*)*",
          redirect: `/`,
        },
      ],
    },
  ]
})

function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    context.next(...parameters);
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({
      ...context,
      next: nextMiddleware
    });
  };
}

router.beforeEach((to, from, next) => {

    const getHTMLTag = document.documentElement;
    getHTMLTag.setAttribute("lang", 'ar');
    getHTMLTag.setAttribute("dir",  "rtl" );

    if (to.meta.middleware) {
      const middleware = Array.isArray(to.meta.middleware) ?
        to.meta.middleware : [to.meta.middleware];

      const context = {
        from,
        next,
        router,
        to,
      };
      const nextMiddleware = nextFactory(context, middleware, 1);

      return middleware[0]({
        ...context,
        next: nextMiddleware
      });
    }


    return next({
      name: 'home',
    });





});
// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    // appLoading.style.display = 'none';
  }
});

router.resolve({
  name: "home",
}).href;
export default router